import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import Modal from './../components/common/Modal'
import HomepageHero from './../components/page-components/HomepageHero'
import Breakdown from '../components/page-components/Breakdown'
import Challenges from './../components/page-components/Challenges'
import Prizes from './../components/page-components/Prizes'
import FAQs from './../components/page-components/FAQs'
import SponsorsBanner from './../components/page-components/SponsorsBanner'
import Footer from './../components/common/Footer'

export default function Home({ location }) {
  const [emailSubmitted, setEmailSubmitted] = useState(location.search == '?thanks');
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />
        <script type="text/javascript" async src="https://www.googletagmanager.com/gtag/js?id=G-WMJ3Y8WYJM"></script>
        {/*  */}
        <meta property="og:title" content="Bitcoin Alliance" />
        <meta property="og:image" content="https://i.ibb.co/tBTnFMf/Website-Thumbnail-Image-1.png" />
        <meta property="og:description" content="Banco Hipotecario invita a todos los desarroladores, diseñadores, emprendedores y aquellos con ideas increíbles al Bitcoin Bankathon:  Una competición destinada a crear innovadoras soluciones bancarias  basadas en Bitcoin, que faciliten el acceso a servicios financieros mejorados para los ciudadanos salvadoreños." />
        <meta property="og:url" content="https://bitcoin-alliance.org/" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="626" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header hero={<HomepageHero/>} />
      <main>
        <Breakdown />
        <Challenges />
        <Prizes />
        <SponsorsBanner />
        <FAQs />
      </main>
      <Footer />
      <Modal show={emailSubmitted} />
    </Root>
  )
}
